import * as React from "react";
import { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.scss";

const times = [9, 10, 11, 12, 13, 14, 15, 16, 17];

const ContactCalendar = ({ handleCallDateTime, activeCalendar }) => {

  const [callDate, setCallDate] = useState(new Date());
  const [callTime, setCallTime] = useState("10");

  useEffect(
    () => (handleCallDateTime ? handleCallDateTime(callDate, callTime) : ""),
    [callDate, callTime]
  );

  useEffect(() => {if(!activeCalendar) {
    setCallDate(null);
    setCallTime(null);
  }});
  
  return (
    <>
      <div className={`calendar-wrapper ${!activeCalendar ? "calendar-inactive" : ""}`}>
        <Calendar
          className={["c1", "c2"]}
          onChange={e => setCallDate(e)}
          view="month"
          calendarType="US"
          showFixedNumberOfWeeks={true}
          value={callDate}
          tileDisabled={({ activeStartDate, date, view }) => date < new Date()}
          next2Label={null}
          prev2Label={null}
          tileClassName={({ activeStartDate, date, view }) => {
            return date.toString()===callDate?.toString() ? "chosen-date" : "";
          }}
        />
        <div className="buttons-wrapper">
          {times.map(time => (
            <button
              key={time}
              className="time-button"
              value={time}
              style={
                time===callTime
                  ? { color: "#FCF8F5", backgroundColor: "#023047" }
                  : { outline: "none" }
              }
              onClick={() => setCallTime(time)}
            >
              {time}:00
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContactCalendar;
