import * as React from "react";
import * as styles from "../ContactForm.module.scss";

const FormInputs = () => {
  return (
    <div className={styles.form_inputs}>
      <div className={styles.name}>
        <label style={{ display: "block", lineHeight: "30px" }}>Name*</label>
        <input type="text" name="FullName" placeholder="John Doe" required />
      </div>
      <div className={styles.email}>
        <label style={{ display: "block", lineHeight: "30px" }}>Email*</label>
        <input
          type="email"
          name="Email"
          placeholder="johndoe@mail.com"
          required
        />
      </div>
      <div className={styles.phone} style={{display: "flex"}}>
        <input type="tel" name="phone" placeholder="Phone" />
      </div>
      <div className={styles.company} style={{display: "flex"}}>
        <input type="text" name="company" placeholder="Company" />
      </div>
      <div className={styles.textarea}>
        <textarea name="textarea" placeholder="Tell us about your project" />
      </div>
    </div>
  );
};

export default FormInputs;
