// extracted by mini-css-extract-plugin
export const contact_form_title = "ContactForm-module--contact_form_title--22-8e";
export const contact_toggle = "ContactForm-module--contact_toggle--3xeRx";
export const switch_container = "ContactForm-module--switch_container--3U2jN";
export const toggle_btn = "ContactForm-module--toggle_btn--tah_D";
export const toggle_btn_on = "ContactForm-module--toggle_btn_on--1lk9g";
export const toggleInput = "ContactForm-module--toggle-input--17Frn";
export const contact_title = "ContactForm-module--contact_title--2tWF-";
export const form_img_container = "ContactForm-module--form_img_container--ZSuh9";
export const calendar_disabled = "ContactForm-module--calendar_disabled--3q0aB";
export const means_container = "ContactForm-module--means_container--11ZJk";
export const form_grid = "ContactForm-module--form_grid--3hq1w";
export const form_inputs = "ContactForm-module--form_inputs--2RzE-";
export const email = "ContactForm-module--email--2FMos";
export const textarea = "ContactForm-module--textarea--OCAQR";
export const contact_calendar = "ContactForm-module--contact_calendar--1-j2l";
export const submit = "ContactForm-module--submit--1QLuZ";
export const bouncy = "ContactForm-module--bouncy--Tw9T7";
export const pop = "ContactForm-module--pop--2WlGu";
export const appear = "ContactForm-module--appear--6x08v";