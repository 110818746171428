import React, { useState, useEffect } from "react";
import * as styles from "./ContactForm.module.scss";
import ContactCalendar from "./Calendar";
import FormInputs from "./FormInputs";

const Switch = props => {
  const { toggleCalendar, activeCalendar } = props;

  return (
    <div style={{ textAlign: "center" }} className={styles.switch_container}>
      <h3>Schedule a call</h3>
      <label style={{ display: "block" }}>
        <input
          className="toggle-input"
          type="checkbox"
          checked={activeCalendar}
          onChange={toggleCalendar}
          style={{ display: "none" }}
        />
        <span
          className={`${styles.toggle_btn} ${
            !activeCalendar ? styles.toggle_btn_on : ""
          }`}
        />
      </label>
    </div>
  );
};

const ContactForm = ({ noTitle }) => {
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [callDateTime, setCallDateTime] = useState("No call");

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, []);

  const toggleCalendar = () => {
    setActiveCalendar(!activeCalendar);
  };

  const handleCallDateTime = (callDate, callTime) => {
    setCallDateTime(`${callDate?.toLocaleDateString()} ${callTime}:00`);
  };

  return (
    <section id="contact-form" style={{marginBottom: "100px"}}>
      {!noTitle && <h2 className={styles.contact_form_title}>Let's Talk!</h2> }
      <form
        className={styles.form_grid}
        method="post"
        action="https://formspree.io/f/mzbyplgd"
      >
        <FormInputs />
        <input
          type="text"
          style={{ display: "none" }}
          name="Call date and time"
          value={callDateTime}
          onChange={() => callDateTime}
        />
        <Switch
          toggleCalendar={toggleCalendar}
          activeCalendar={activeCalendar}
        />
        <div
          className={`${!activeCalendar ? styles.calendar_disabled : ""} ${
            styles.contact_calendar
          }`}
          onClick={() => {if(!activeCalendar) setActiveCalendar(true)}}
        >
          {(activeCalendar || screenWidth > 1024) && (
            <ContactCalendar handleCallDateTime={handleCallDateTime} activeCalendar={activeCalendar} />
          )}
        </div>
        <input type="submit" value="Submit" className={styles.submit}></input>
      </form>
    </section>
  );
};

export default ContactForm;
